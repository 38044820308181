<template>
  <div class="hero">
    <teleport to="head">
      <link  rel="preload" as="image" href="../../assets/img/home-page/hero-new.jpg" />
      <!-- <link rel="preload" href="https://fonts.googleapis.com/css2?family=Tajawal:wght@400;700&display=swap" as="style" onload="this.onload=null;this.rel='stylesheet'">
      <noscript><link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Tajawal:wght@400;700&display=swap"></noscript> -->
    </teleport>

    <img
      src="../../assets/img/home-page/hero-new.jpg"
      class="cover"
      alt="Beautiful Hero Section Background"
      accept="image/webp"
      decoding="async"
      loading="eager"
      width="1440"
      height="704"
      style="opacity:.2"
    />

    <div class="hero-data">
      <div class="container">
        <div class="align-items-center row">
          <div class="col-12 col-md-12 col-lg-6 info">
              <h1 data-aos="fade-up">{{ main_title }}</h1>
              <h2 data-aos="fade-up">{{ secondary_title }}
                <span class="text-dot"></span>
              </h2>
              <p data-aos="fade-up">{{ hero_description }}</p>
            <div class="d-flex">
              <router-link data-aos="fade-up" to="/services" class="btn-main secondary">
                {{ $t("Services") }}
              </router-link>
              <router-link data-aos="fade-up" to="/about" class="btn-main second_btn secondary">
                {{ $t("Learn More") }}
              </router-link>
            </div>

          </div>
          <div class="col-12 col-md-2 d-none d-lg-block"></div>
          <div class="col-12 col-md-12 col-lg-4 d-none d-lg-block" style="z-index: 1;">
              <div
              :class="[
                  'guide',
                  $i18n.locale === 'ar' ? 'guide-ar' : 'guide-en',
                ]"

              >
                <div class="hero-cards d-flex flex-column justify-content-center align-items-center">
                <router-link to="/about">
                  <div
                    class="item"

                    :class="[
                  'guide',
                  { animate__animated: isHovered1, animate__pulse: isHovered1 },
                  $i18n.locale === 'ar' ? 'guide-ar' : 'guide-en',
                ]"
                @mouseenter="handleMouseEnter1"
                @mouseleave="handleMouseLeave1"
                  >
                    <img
                      :src="heroCards[0]?.photo"
                      alt="hero card"
                      loading="lazy"
                      style="width: 50px; height: 50px"
                    />
                    <div>
                      <h2 class="title">{{ heroCards[0]?.title }}</h2>
                      <p class="disc">{{ heroCards[0]?.description }}</p>
                    </div>
                  </div>
                </router-link>
                <router-link to="/services">
                  <div
                    class="item"

                    :class="[
                  'guide',
                  { animate__animated: isHovered2, animate__pulse: isHovered2 },
                  $i18n.locale === 'ar' ? 'guide-ar' : 'guide-en',
                ]"
                @mouseenter="handleMouseEnter2"
                @mouseleave="handleMouseLeave2"
                  >
                    <img
                      :src="heroCards[1]?.photo"
                      alt="hero card"
                      loading="lazy"
                      style="width: 50px; height: 50px"
                    />
                    <div>
                      <h2 class="title">{{ heroCards[1]?.title }}</h2>
                      <p class="disc">{{ heroCards[1]?.description }}</p>
                    </div>
                  </div>
                </router-link>
                <router-link to="/contact">
                  <div
                    class="item"

                    :class="[
                  'guide',
                  { animate__animated: isHovered3, animate__pulse: isHovered3 },
                  $i18n.locale === 'ar' ? 'guide-ar' : 'guide-en',
                ]"
                @mouseenter="handleMouseEnter3"
                @mouseleave="handleMouseLeave3"
                  >
                    <img
                      :src="heroCards[2]?.photo"
                      alt="hero card"
                      loading="lazy"
                      style="width: 50px; height: 50px"
                    />
                    <div>
                      <h2 class="title">{{ heroCards[2]?.title }}</h2>
                      <p class="disc">{{ heroCards[2]?.description }}</p>
                    </div>
                  </div>
                  </router-link>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isHovered1: false,
      isHovered2: false,
      isHovered3: false,
      image: "",
      main_title: "",
      secondary_title: "",
      hero_description: "",
      heroCards: [],
    };
  },
  methods: {
    handleMouseEnter1() {
      this.isHovered1 = true;
    },
    handleMouseLeave1() {
      this.isHovered1 = false;
    },
    handleMouseEnter2() {
      this.isHovered2 = true;
    },
    handleMouseLeave2() {
      this.isHovered2 = false;
    },
    handleMouseEnter3() {
      this.isHovered3 = true;
    },
    handleMouseLeave3() {
      this.isHovered3 = false;
    },
    handleStaticHero(StaticContent) {
      const contentMap = {
        hero_main_photo: "image",
        main_title: "main_title",
        secoundary_title: "secondary_title",
        hero_description: "hero_description",
      };

      StaticContent.forEach((item) => {
        if (contentMap[item.key]) {
          this[contentMap[item.key]] = item.value;
        }
      });

      this.heroCards = [
        {
          photo:
            StaticContent.find((item) => item.key === "hero_card_1_photo")
              ?.value || "",
          title:
            StaticContent.find((item) => item.key === "hero_card_1_title")
              ?.value || "",
          description:
            StaticContent.find((item) => item.key === "hero_card_1_description")
              ?.value || "",
        },
        {
          photo:
            StaticContent.find((item) => item.key === "hero_card_2_photo")
              ?.value || "",
          title:
            StaticContent.find((item) => item.key === "hero_card_2_title")
              ?.value || "",
          description:
            StaticContent.find((item) => item.key === "hero_card_2_description")
              ?.value || "",
        },
        {
          photo:
            StaticContent.find((item) => item.key === "hero_card_3_photo")
              ?.value || "",
          title:
            StaticContent.find((item) => item.key === "hero_card_3_title")
              ?.value || "",
          description:
            StaticContent.find((item) => item.key === "hero_card_3_description")
              ?.value || "",
        },
      ];
    },
  },
  mounted() {
    this.$store.dispatch("GetStaticHeroSection").then(() => {
      this.handleStaticHero(this.$store.state.StaticHeroSection);
    });
  },
};
</script>
<style lang="scss" scoped>
.hero {
  padding-top: 180px;
  background: linear-gradient(180deg, #0a3041 0%, #0349A8 100%);
  background-size: cover;
  border-radius: 0 0 40px 40px;
  overflow: hidden;
  position: relative;
  z-index: 0;
  .btn-main {
    font-size: 16px !important;
    &:hover {
      transition: all .5s ease-in;
    }
    &.second_btn {
      margin-inline-start: 10px;
      background-color: #2a6ed1 !important;
      border-color: #2a6ed1 !important;
    }
  }
  @media (max-width: 1024px) {
    .cover {
      height: -webkit-fill-available;
    }
  }

  @media (max-width: 768px) {
    border-radius: 0;
    padding-top: 137px;
    .cover {
      height: 100%;
    }
  }

  .info {
    position: relative;
    z-index: 2;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .info {
      padding-inline-start: 70px;
    }
  }

  @media (min-width: 1024px) {
    /* Changed max-width to 2000px */
    .info {
      padding-inline-start: 40px;
    }
  }

  h1,
  h2 {
    color: #ffffffff;
    font-style: normal;
    line-height: normal;
    text-align: start;
  }

  h1 {
    font-size: 37px;
    font-weight: 800;
    margin: 9vh 0 0;

    @media (max-width: 768px) {
      margin-top: 40px;
      font-size: 20px;
    }
  }

  h2 {
    font-size: 37px;
    font-weight: 700;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  p {
    color: #ffffffff;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: start;
    width: 55%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

.hero-data{
    padding: 3rem 0 4rem;
    @media (max-width:768px) {
      padding: 90px 0 5rem;
    }
    .info{

        // padding-inline-start: 0;
        @media (max-width:768px) {
            align-items: start;
            flex-flow: column;
            display: flex;
            // padding-inline-end: 0;
            h1{
              font-size: 32px;
              margin-bottom: 5px;
            }
            h2{
              margin-bottom: 30px;
            }
            p{
              margin-bottom: 25px;
            }
            a{}
        }
    }
    .guide{
        margin: 0;
        padding: 0;
        background: transparent;
    }
    p{
      width: 100%;
    }
    h1{
      margin: 0;
    }
}
}

.cover {
  object-fit: cover;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1;
}

.guide {
  margin-top: 8vh;
  position: relative;
  background: #0a3041cc;
  padding: 18px 44px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-top: 50px;
    padding: 32px 44px 12px;
  }

  h2 {
    color: #ffffffff;
    font-size: 26px;
    font-weight: 700;
    @media (max-width: 992px) {
      font-size: 22px;
    }
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  p {
    color: #ffffffff;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    @media (max-width: 992px) {
      font-size: 14px;
    }
  }

  .item {
    display: flex;
    align-items: center;
    overflow: hidden;
    transition: transform 0.3s ease;
    margin-bottom: 10px;
    min-height: 120px; /* Adjust based on the maximum expected content */

    p.disc {
      line-height: 1.5;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 42px; /* Reserve space */
    }
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }

    img {
      margin-inline-end: 20px;
      max-width: 50px;
    }

    &:hover {
      // transform: scale(1.1);
    }
  }
}

/* Language-specific Styles */
.guide-ar {
  border-radius: 0 20px 0 0;
}

.guide-en {
  border-radius: 20px 0 0 0;
}

.hero-order {
  padding-top: 180px;
  background: #fff;

  p {
    text-align: start;
    font-size: 18px;
    font-weight: 700;
    color: #afafaf;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  h1 {
    color: #0a3041;
    text-align: start;
    font-size: 40px;
    font-weight: 700;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 28px;
      margin-bottom: 40px;
    }
  }
}
.container-fluid.info {
  min-height: 100px; /* Prevent layout shifts */
  margin-top: 0px;
}
.text-dot{
    background: #00aeee;
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 0 px;
    vertical-align: inherit;
  }
  @media (min-width: 767px) and (max-width: 992px) {
    .hero-cards {
      flex-direction: unset !important;
    }
    .hero .hero-data {
      padding: 1rem 0 3rem !important;
    }
    .hero .hero-data .guide {
      display: block;
      margin: 0 6px;
      padding-top: 10px;
    }
  }
@media (min-width: 1025px) {
 .hero-data .d-lg-block {
    display: block !important;
  }
}
@media (max-width: 1024px) {
  .hero-data .d-none {
    display: none !important;
  }
  .info {
    width: 100%;
  }
}
</style>
